import React from 'react'
import { toCssPrefix, breakpoints, Anchor } from '@ecommerce/shared'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Slider from '../FramerSlider'
import RecipeSlide from './Slide'
import { Icon } from '../Icon/Icon'
import SkeletonItem from '../Skeletons/SkeletonItem'
import SectionTitle from '../Moments/SectionTitle'
import useQuery from '../../hooks/useQuery'
import recipeListQuery, { QueryRecipeListResult } from '../../graphql/recipeListQuery'

const { cssPrefix, toPrefix } = toCssPrefix('MomentsRecipes__')

type Props = React.HTMLAttributes<HTMLDivElement> & {
  actionSlug?: string
  themeId?: string
  listId: string
}

const Wrapper = styled.div<{
  allowTitleLowerCase?: boolean
  titleColor?: string
  centerTitle?: boolean
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  backgroundColor?: string
  background?: { url: string }
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 56px;
  overflow: hidden;
  padding: 56px 32px;
  position: relative;
  background-image: ${({ background }) => `url(${background?.url})`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 0 auto;

  .grid {
    display: flex;
    gap: 32px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .section-title {
    margin: 0;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .grid {
      column-gap: 24px;
      row-gap: 48px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .grid {
      column-gap: 40px;
      row-gap: 56px;
    }
  }
`

const RecipeList = ({ actionSlug, className = '', themeId, listId }: Props) => {
  const { data, loading, error } = useQuery<QueryRecipeListResult>(recipeListQuery, { variables: { listId } })

  const orList = data?.orList
  const recipes = orList?.itemsCollection.items

  if (error !== undefined || (!recipes?.length && !loading)) return null

  if (recipes?.length === 0 || !recipes) return null

  return (
    <Wrapper {...orList} className={className}>
      {orList?.title && (
        <SectionTitle className="section-title" text={orList?.title} color={orList?.titleColor} {...orList} />
      )}
      {actionSlug && (
        <Anchor className={toPrefix('show-more')} onClick={() => navigate(actionSlug)}>
          Ver todo
          <Icon className={toPrefix('show-more-button')} iconId="chevron_right" size="26" />
        </Anchor>
      )}
      {/* <Slider className={toPrefix('slider')} itemsCount={recipes.length}> */}
      <div className="grid">
        {loading ? (
          Array(6)
            .fill(0)
            .map((_, index) => <SkeletonItem animate width="300px" height={190} margin="0 15px 0" key={index} />)
        ) : (
          <>
            {recipes.map((recipe) => (
              <RecipeSlide
                themeId={themeId}
                key={recipe.sys.id}
                data={{
                  primaryTextButton: recipe.primaryTextButton,
                  title: recipe.sliderTitle,
                  description: recipe.sliderDescription,
                  image: recipe.sliderImage.url,
                  slug: `/${recipe.slug}`,
                  backgroundColorCard: recipe.backgroundColorCard,
                  textColorCard: recipe.textColorCard,
                }}
              />
            ))}
          </>
        )}
      </div>
      {/* </Slider> */}
    </Wrapper>
  )
}

export default RecipeList
